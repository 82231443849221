import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';

export default class DateRangeFilter extends Component {
    state = {
        focusedInput: null,
        endDate: this.props.dateEnd,
        startDate: this.props.dateStart,
    };

    handleOpenMenu = (e) => {
        this.setState({
            [e.currentTarget.dataset.anchor]: e.currentTarget,
            firstRender: false,
        });
    };

    handleInputFocusChange = (focusedInput) => {
        this.setState(
            {
                focusedInput,
            },
            async () => {
                if (focusedInput === null) {
                    await this.props.onClose(
                        this.state.startDate,
                        this.state.endDate
                    );
                }
            }
        );
    };

    handleDateChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    };

    render() {
        return (
            <DateRangePicker
                endDate={this.state.endDate}
                endDateId='logsEndDateId'
                startDate={this.state.startDate}
                startDateId='logsStartDateId'
                focusedInput={this.state.focusedInput}
                onFocusChange={this.handleInputFocusChange}
                onDatesChange={this.handleDateChange}
                displayFormat='DD MMM YYYY'
                enableOutsideDays={true}
                isOutsideRange={(date) => !date.isBefore(moment())}
                hideKeyboardShortcutsPanel={true}
            />
        );
    }
}
