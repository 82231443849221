import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import apiCall from '../../../helpers/apiCall';

class TermsSettings extends Component {
    state = {
        value: '',
    };

    async componentDidMount() {
        const { response, success, message } = await apiCall(
            'GET',
            '/settings/' + this.props.settingKey
        );

        if (success) {
            this.setState({
                value: response ?? '',
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    }

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { message, success } = await apiCall(
            'POST',
            '/settings/save/' + this.props.settingKey,
            {
                value: this.state.value,
            }
        );

        if (success) {
            this.props.setGlobalAlert({
                type: 'success',
                message: this.props.title + ' saved!',
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    handleCKEditorChange = (e, editor) => {
        const value = editor.getData();
        this.setState({
            value,
        });
    };

    setCKEditor = (CKEditor) => {
        this.setState({
            CKEditor,
        });
    };

    render() {
        return (
            <div className='form'>
                <div className='form__content'>
                    <form action='/' onSubmit={this.handleFormSubmit}>
                        <div className='form__field'>
                            <CKEditor
                                name='message'
                                editor={ClassicEditor}
                                data={this.state.value}
                                onChange={this.handleCKEditorChange}
                                onRead={this.setCKEditor}
                            />
                        </div>
                        <div className='form__buttons'>
                            <Button type='submit' variant='primary'>
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(TermsSettings);
