import '../Admin/Messages/Messages.scss';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import {
    Accordion,
    Button,
    Card,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

export default class Notification extends Component {
    render() {
        const { doc } = this.props;
        return (
            <Accordion>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as='div'
                            className='card-title'
                            eventKey={doc._id}>
                            {doc.subject}
                        </Accordion.Toggle>
                        <div className='card-controls float-right'>
                            {!doc.readAt && (
                                <OverlayTrigger
                                    placement='left'
                                    overlay={
                                        <Tooltip
                                            id={`notification-${doc._id}__tooltip__courses`}>
                                            Mark as read
                                        </Tooltip>
                                    }>
                                    <Button
                                        variant='outline-primary'
                                        size='sm'
                                        onClick={this.props.markAsRead}>
                                        <Fa icon={faEye} />
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={doc._id}>
                        <Card.Body>
                            {doc.emailSentAt && (
                                <p className='color--light'>
                                    Email sent at {doc.emailSentAt}
                                </p>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: doc.message,
                                }}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
