import React, { Component } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async/dist/react-select.esm';

import apiCall from '../../../helpers/apiCall';

class UserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            redirect: false,
            groups: [],
        };

        if (props.user) {
            this.state = {
                ...this.state,
                initialUser: props.user,
                ...props.user,
            };
        }
    }

    async componentDidMount() {
        if (this.props.user) {
            const { response, success, message } = await apiCall(
                'GET',
                '/users/' + this.props.user._id + '/groups'
            );

            if (success) {
                this.setState({
                    groups: response,
                });
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message,
                });
            }
        }
    }

    // we don't need to send everything to the backend, just what's changed
    toUpdate = () => {
        let toReturn = {};

        for (const key of Object.keys(this.state.initialUser)) {
            if (this.state[key] !== this.state.initialUser[key]) {
                if (key === 'groups') {
                    toReturn[key] = this.state[key].map((group) => group.value);
                } else {
                    toReturn[key] = this.state[key];
                }
            }
        }

        return toReturn;
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const payload = this.props.user
            ? this.toUpdate()
            : {
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  email: this.state.email,
                  county: this.state.county,
                  townCity: this.state.townCity,
                  addressLineOne: this.state.addressLineOne,
                  addressLineTwo: this.state.addressLineTwo,
                  phoneNumber: this.state.phoneNumber,
                  timeZone: this.state.timeZone,
                  postCode: this.state.postCode,
                  groups: this.state.groups.map((group) => group.value),
              };

        if (!payload) {
            this.setState({
                message: {
                    type: 'success',
                    text: 'User updated',
                },
            });
        } else {
            let { success, message: text } = await apiCall(
                this.props.user ? 'PUT' : 'POST',
                '/users' +
                    (this.props.user ? `/${this.state.initialUser._id}` : ''),
                payload
            );

            if (success) {
                this.props.setGlobalAlert({
                    type: 'success',
                    message: text,
                });

                if (!this.props.user) {
                    this.setState({
                        redirect: true,
                        message: null,
                    });
                } else {
                    this.setState({ message: null });
                }
            } else {
                this.setState({
                    message: {
                        type: 'warning',
                        text,
                    },
                });
            }
        }
    };

    handleInputChange = (e) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to='/admin/users' />;
        }
        const { message } = this.state;
        return (
            <section id='user-profile'>
                <div className={'form' + (message ? ' form--with-error' : '')}>
                    {message && (
                        <Alert variant={message.type}>{message.text}</Alert>
                    )}
                    <div className='form__content'>
                        <form action='/' onSubmit={this.handleFormSubmit}>
                            <Row>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='firstName'>
                                            First name
                                        </label>
                                        <input
                                            type='text'
                                            name='firstName'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.firstName}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='lastName'>
                                            Last name
                                        </label>
                                        <input
                                            type='text'
                                            name='lastName'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.lastName}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='email'>Email</label>
                                        <input
                                            type='text'
                                            name='email'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.email}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='phoneNumber'>
                                            Phone number
                                        </label>
                                        <input
                                            type='text'
                                            name='phoneNumber'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state.phoneNumber
                                            }
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='timeZone'>
                                            Time zone
                                        </label>
                                        <input
                                            type='text'
                                            name='timeZone'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.timeZone}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='addressLineOne'>
                                            Address line 1
                                        </label>
                                        <input
                                            type='text'
                                            name='addressLineOne'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state.addressLineOne
                                            }
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='addressLineTwo'>
                                            Address line 2
                                        </label>
                                        <input
                                            type='text'
                                            name='addressLineTwo'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state.addressLineTwo
                                            }
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='townCity'>
                                            Town / City
                                        </label>
                                        <input
                                            type='text'
                                            name='townCity'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.townCity}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='county'>County</label>
                                        <input
                                            type='text'
                                            name='county'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.county}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='postCode'>
                                            Post code
                                        </label>
                                        <input
                                            type='text'
                                            name='postCode'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.postCode}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='groups'>Groups</label>
                                        <AsyncSelect
                                            defaultOptions
                                            value={this.state.groups}
                                            isMulti
                                            label='groups'
                                            loadOptions={async (inputValue) => {
                                                const {
                                                    success,
                                                    response,
                                                } = await apiCall(
                                                    'GET',
                                                    '/groups' +
                                                        (inputValue
                                                            ? `?search=${inputValue}`
                                                            : '')
                                                );

                                                if (success) {
                                                    return response.docs.map(
                                                        ({
                                                            _id: value,
                                                            name: label,
                                                        }) => ({
                                                            value,
                                                            label,
                                                        })
                                                    );
                                                }
                                            }}
                                            placeholder='Select a user to add to the group'
                                            onChange={(groups) => {
                                                this.setState({
                                                    groups,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {this.props.user && (
                                <Row>
                                    <Col>
                                        <div />
                                        <div className='form__field'>
                                            <label htmlFor='adminNotes'>
                                                Admin notes
                                            </label>
                                            <textarea
                                                name='adminNotes'
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                defaultValue={
                                                    this.state.adminNotes
                                                }
                                                rows='6'
                                                style={{
                                                    minHeight: '6rem',
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <div className='form__buttons'>
                                <Button type='submit' variant='primary'>
                                    {this.props.user ? 'Save' : 'Create'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(UserForm));
