import './Graph.scss';

import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import apiCall from '../../../helpers/apiCall';
import DateRangeFilter from './DateRangeFilter';
import DownloadButton from './DownloadButton';

// TODO - improve to not make unnecessary calls to the API
class Graph extends Component {
    state = {
        loading: true,
        chartData: [],
        fieldsToSelect: ['volume', 'flow', 'pressure', 'turbidity'],
        dateStart: moment('04-01-21').add({ days: -30 }),
        dateEnd: moment('04-01-2021'),
        skip: 0,
        change: true,
        firstRender: true,
        message: null,
    };

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        if (this.state.change) {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                });
            }

            let query = new URLSearchParams();

            query.set('fieldsToSelect', 'volume,flow,pressure,turbidity');
            query.set('skip', this.state.skip);

            if (this.state.dateStart) {
                query.set('dateStart', this.state.dateStart.toISOString());
            }

            if (this.state.dateEnd) {
                query.set('dateEnd', this.state.dateEnd.toISOString());
            }

            const { success, response, message } = await apiCall(
                    'GET',
                    '/devices/' +
                        this.props.deviceId +
                        '/logs?' +
                        query.toString()
                ),
                { fieldsToSelect } = this.state;
            let chartData = [['Timestamp']];

            chartData[0].push(
                ...fieldsToSelect.map(
                    (v) => v.charAt(0).toUpperCase() + v.slice(1)
                )
            );

            if (success) {
                if (!response.logs) {
                    chartData = null;
                } else {
                    for (const row of response.logs) {
                        const arr = [new Date(row.timestamp)];

                        for (const field of fieldsToSelect) {
                            arr.push(row[field]);
                        }

                        chartData.push(arr);
                    }
                }

                this.setState({
                    chartData,
                    message:
                        chartData === null
                            ? 'No data found for the selected filters'
                            : null,
                    loading: false,
                    change: false,
                });
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message,
                });
            }
        }
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            change: true,
            skip: 0,
        });
    };

    handleNextDataSet = () => {
        this.setState(
            {
                skip: this.state.skip < 1000 ? 0 : this.state.skip - 1000,
                change: true,
            },
            this.loadData
        );
    };

    handlePreviousDataSet = () => {
        this.setState(
            {
                skip: this.state.skip + 1000,
                change: true,
            },
            this.loadData
        );
    };

    handleDateChange = (dateStart, dateEnd) => {
        const { dateStart: currentStart, dateEnd: currentEnd } = this.state;

        if (dateStart !== currentStart || dateEnd !== currentEnd) {
            this.setState(
                {
                    dateStart,
                    dateEnd,
                    change: true,
                },
                this.loadData
            );
        }
    };

    render() {
        return (
            <div className='log-graph'>
                <div>
                    <div className='graph-controls flex p-2'>
                        <DateRangeFilter
                            onClose={this.handleDateChange}
                            dateEnd={this.state.dateEnd}
                            dateStart={this.state.dateStart}
                        />
                        <DownloadButton />
                        <div className='mx-3 border-r border-solid self-center border-gray-400 h-7'></div>
                        <Tooltip
                            title='Show previous set of data'
                            placement='top'>
                            <div>
                                <Button
                                    variant='outlined'
                                    onClick={this.handlePreviousDataSet}>
                                    <Fa icon={faChevronLeft} />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title='Show next set of data' placement='top'>
                            <div>
                                <Button
                                    className='ml-2'
                                    variant='outlined'
                                    disabled={this.state.skip === 0}
                                    onClick={this.handleNextDataSet}>
                                    <Fa icon={faChevronRight} />
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                    {this.state.message ? (
                        <div className='p-4 text-center font-weight-bold text-xl'>
                            {this.state.message}
                        </div>
                    ) : this.state.loading ? (
                        <div className='center-loading big'>
                            <Spinner animation='border' />
                        </div>
                    ) : (
                        <Chart
                            width='100%'
                            height='600px'
                            chartType='LineChart'
                            type='number'
                            options={{
                                chartArea: {
                                    left: 15,
                                    right: 150,
                                    top: 32,
                                    bottom: 0,
                                },
                                legend: {
                                    position: 'top',
                                },
                                explorer: {
                                    keepInBounds: true,
                                    actions: [
                                        'dragToZoom',
                                        'rightClickToReset',
                                    ],
                                    axis: 'horizontal',
                                    maxZoomIn: 0.05,
                                },
                            }}
                            data={this.state.chartData}
                            legendToggle
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(Graph));
