export const allLessons = (state = null, action) => {
    switch (action.type) {
        case 'SET_ALL_LESSONS':
            return {
                ...state,
                state: action.payload,
            };
        default:
            return state;
    }
};
export const currentLesson = (state = null, action) => {
    switch (action.type) {
        case 'SET_CURRENT_LESSON':
            return {
                ...state,
                state: action.payload,
            };
        default:
            return state;
    }
};
