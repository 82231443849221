import './Messages.scss';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import MessageForm from './MessageForm';
import MessagesList from './MessagesList';

export default class Messages extends Component {
    constructor(props) {
        super(props);

        this.sendingConditions = {
            GLOBAL_VARIABLES: [
                'website.title',
                'website.url',
                'user.firstName',
                'user.lastName',
                'user.email',
            ],
            USER_REGISTER: {
                text: 'User registration',
                availableVariables: [],
            },
            USER_COURSE_START: {
                text: 'Course started',
                availableVariables: [],
            },
            USER_COURSE_EXPIRY: {
                text: 'Course expires',
                availableVariables: [],
            },
            USER_EXAM_PASS: {
                text: 'User passes exam',
                availableVariables: [],
            },
            USER_EXAM_FAIL: {
                text: 'User fails exam',
                availableVariables: [],
            },
            COURSE_LESSON_CREATE: {
                text: 'New lesson added to the course',
                availableVariables: [],
            },
        };
    }
    render() {
        return (
            <div id='messages'>
                <Switch>
                    <Route exact path='/admin/messages'>
                        <MessagesList
                            sendingConditions={this.sendingConditions}
                        />
                    </Route>
                    <Route exact path='/admin/messages/:id'>
                        <MessageForm
                            sendingConditions={this.sendingConditions}
                        />
                    </Route>
                </Switch>
            </div>
        );
    }
}
