import './Admin.scss';

import {
    faBolt,
    faCogs,
    faEnvelope,
    faTabletAlt,
    faTachometerAlt,
    faUsers,
    faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Menu, MenuItem } from '../components/Menu';

class Admin extends Component {
    render() {
        if (!this.props.loggedIn.user.adminLevel) {
            return <Redirect to='/' />;
        }

        return (
            <div className={'admin-layout'}>
                <Menu basePath='/admin'>
                    <MenuItem to='/dashboard' icon={faTachometerAlt}>
                        Dashboard
                    </MenuItem>
                    <MenuItem to='/settings' icon={faCogs}>
                        Settings
                    </MenuItem>
                    <MenuItem to='/sessions' icon={faBolt}>
                        Sessions
                    </MenuItem>
                    <MenuItem to='/messages' icon={faEnvelope}>
                        Messages
                    </MenuItem>
                    <MenuItem to='/users' icon={faUsers}>
                        Users
                    </MenuItem>
                    <MenuItem to='/groups' icon={faUsersCog}>
                        Groups
                    </MenuItem>
                    <MenuItem to='/devices' icon={faTabletAlt}>
                        Devices
                    </MenuItem>
                </Menu>
                <main>
                    <div className='admin-wrapper'>{this.props.children}</div>
                </main>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        loggedIn: state.loggedIn,
        breadcrumbList: state.globalBreadcrumb,
    };
})(Admin);
