import './Main.scss';

import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

import apiCall from '../../helpers/apiCall';
import UserBar from './UserBar';

class Main extends Component {
    state = {
        loading: true,
        globalAlert: null,
        showGlobalAlert: false,
    };

    publicUrls = [
        '/login',
        '/reset-password',
        '/change-password',
        '/terms_of_service',
        '/privacy_policy',
    ];

    async componentDidMount() {
        const token = localStorage.getItem('authToken');

        if (token && !this.props.loggedIn.token) {
            const { success, response: user } = await apiCall(
                'POST',
                '/users/auth',
                null,
                token
            );
            this.unsetGlobalAlertTimeout = null;
            if (success) {
                this.props.setLoggedIn({
                    token,
                    user,
                });
            }
        }

        this.setState({
            loading: false,
        });
    }

    updateGlobalAlert = (globalAlert) => {
        this.setState(
            {
                globalAlert: globalAlert,
            },
            () => {
                this.setState({
                    showGlobalAlert: true,
                });

                setTimeout(() => {
                    this.setState(
                        {
                            showGlobalAlert: false,
                        },
                        () => {
                            this.unsetGlobalAlertTimeout = setTimeout(() => {
                                this.props.unsetGlobalAlert();
                            }, 250);
                        }
                    );
                }, 1750);
            }
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.globalAlert &&
            this.props.globalAlert.message &&
            (!this.state.globalAlert ||
                this.state.globalAlert.ts !== this.props.globalAlert.ts)
        ) {
            const currentTs =
                    this.state.globalAlert && this.state.globalAlert.ts
                        ? this.state.globalAlert.ts
                        : 0,
                tsDiff = this.props.globalAlert.ts - currentTs;

            if (tsDiff < 2000) {
                if (this.unsetGlobalAlertTimeout) {
                    clearTimeout(this.unsetGlobalAlertTimeout);
                }

                setTimeout(() => {
                    this.updateGlobalAlert(this.props.globalAlert);
                }, tsDiff);
            } else {
                this.updateGlobalAlert(this.props.globalAlert);
            }
        }
    }

    render() {
        let mainContent;

        if (this.state.loading) {
            mainContent = (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        } else {
            if (
                !this.props.loggedIn.token &&
                // this array should contain the routes that can be accessed by a guest
                this.publicUrls.indexOf(this.props.location.pathname) === -1
            ) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {
                                previousPath: this.props.location.pathname,
                            },
                        }}
                    />
                );
            } else {
                mainContent = this.props.children;
            }
        }

        return (
            <div className='wrapper'>
                <header>
                    <Link to='/'>
                        <img
                            className='logo-img'
                            src={'/logo.png'}
                            alt='RealEstateU logo'
                        />
                    </Link>

                    <UserBar />
                </header>
                <main>{mainContent}</main>
                {this.state.globalAlert && (
                    <div
                        className={
                            `global-alert global-alert--${this.state.globalAlert.type}` +
                            (this.state.showGlobalAlert
                                ? ' global-alert--visible'
                                : '')
                        }>
                        {this.state.globalAlert.type === 'success' && (
                            <Fa icon={faCheckCircle} />
                        )}
                        {this.state.globalAlert.type === 'error' && (
                            <Fa icon={faTimesCircle} />
                        )}
                        <span className='message'>
                            {this.state.globalAlert.message}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    (state) => ({ loggedIn: state.loggedIn, globalAlert: state.globalAlert }),
    {
        setGlobalAlert: (payload) => ({
            type: 'SET_GLOBAL_ALERT',
            payload,
        }),
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        unsetGlobalAlert: () => ({
            type: 'UNSET_GLOBAL_ALERT',
        }),
    }
)(withRouter(Main));
