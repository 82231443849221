import { faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ApiTable } from '../../../components/ApiTable';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import apiCall from '../../../helpers/apiCall';

class UsersList extends Component {
    state = {
        toDelete: false,
        modalConfirmAction: null,
    };

    constructor(props) {
        super(props);

        props.pushBreadcrumbLink({
            text: 'Users',
            path: '/admin/users',
        });
    }

    unsetUserToDelete = () => {
        this.setState({
            toDelete: false,
        });
    };

    render() {
        return (
            <div>
                <Row className='mb-4'>
                    <Col>
                        <h3>Users</h3>
                    </Col>
                    <Col>
                        <Button
                            className='float-right'
                            as={Link}
                            to='/admin/users/new'>
                            Create new user
                        </Button>
                    </Col>
                </Row>
                <ApiTable
                    basePath='/admin/users'
                    apiCall={{
                        method: 'GET',
                        path: '/users',
                    }}
                    columns={[
                        {
                            text: '',
                            field: (row) => (
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            id={`tooltip-${row._id}-edit-user)}`}>
                                            Edit user
                                        </Tooltip>
                                    }>
                                    <Button
                                        as={Link}
                                        variant='primary'
                                        size='sm'
                                        to={'/admin/users/' + row._id}>
                                        <Fa icon={faUserEdit} />
                                    </Button>
                                </OverlayTrigger>
                            ),
                            maxWidth: '3.11rem',
                        },
                        {
                            text: 'Full name',
                            field: (row) => `${row.firstName} ${row.lastName}`,
                        },
                        {
                            text: 'Email',
                            field: 'email',
                        },
                        {
                            text: 'Phone number',
                            field: 'phoneNumber',
                        },
                        {
                            text: 'Address',
                            field: (row) => {
                                let address = row.addressLineOne;

                                if (row.addressLineTwo) {
                                    address += ` ${row.addressLineTwo}`;
                                }

                                if (row.townCity) {
                                    address += `, ${row.townCity}`;
                                }

                                if (row.zipCode) {
                                    address += ` ${row.zipCode}`;
                                }

                                if (row.state) {
                                    address += `, ${row.state}`;
                                }

                                return address;
                            },
                            minWidth: '35%',
                        },
                    ]}
                    rowButtons={[
                        {
                            text: 'Delete user',
                            icon: faTrash,
                            variant: 'danger',
                            clickCallback: async (e, row, reloadTable) => {
                                this.setState({
                                    toDelete: row,
                                    modalConfirmAction: async () => {
                                        this.setState({
                                            toDelete: false,
                                        });

                                        const {
                                            success,
                                            message,
                                        } = await apiCall(
                                            'DELETE',
                                            '/users/' + row._id
                                        );

                                        if (success) {
                                            this.props.setGlobalAlert({
                                                type: 'success',
                                                message,
                                            });
                                            await reloadTable();
                                        } else {
                                            this.props.setGlobalAlert({
                                                type: 'error',
                                                message,
                                            });
                                        }
                                    },
                                });
                            },
                        },
                    ]}
                />
                <ConfirmationModal
                    show={this.state.toDelete !== false}
                    hideModal={this.unsetUserToDelete}
                    titleText={`Delete user ${
                        this.state.toDelete !== false
                            ? this.state.toDelete.firstName +
                              ' ' +
                              this.state.toDelete.lastName
                            : ''
                    } `}
                    bodyText="Are you sure you want to delete user's account?"
                    confirmAction={this.state.modalConfirmAction}
                />
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
})(UsersList);
