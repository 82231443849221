import './Admin.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import apiCall from '../../helpers/apiCall';
import AdminLayout from '../../layouts/Admin';
import { Dashboard } from './Dashboard';
import { Devices } from './Devices';
import Groups from './Groups/Groups';
import { Messages } from './Messages';
import { Sessions } from './Sessions';
import { Settings } from './Settings';
import { Users } from './Users';

class Admin extends Component {
    async componentDidMount() {
        const token = localStorage.getItem('authToken');

        if (token && !this.props.loggedIn) {
            const { success, response: user } = await apiCall(
                'POST',
                '/users/auth',
                null,
                token
            );

            if (success) {
                this.props.setLoggedIn({
                    token,
                    user,
                });
            }
        }
    }

    render() {
        const breadcrumb = Object.keys(this.props.breadcrumb);
        return (
            <AdminLayout>
                <ul id='breadcrumb'>
                    <li>
                        <Link to='/admin'>Dashboard</Link>
                        {breadcrumb && breadcrumb.length > 0 && (
                            <Fa icon={faChevronRight} />
                        )}
                    </li>
                    {breadcrumb.map((path, index) => {
                        return (
                            <li key={path}>
                                <Link to={path}>
                                    {this.props.breadcrumb[path]}
                                </Link>
                                {index !== breadcrumb.length - 1 && (
                                    <Fa icon={faChevronRight} />
                                )}
                            </li>
                        );
                    })}
                </ul>
                <Switch>
                    <Route path='/admin/dashboard' component={Dashboard} />
                    <Route path='/admin/sessions' component={Sessions} />
                    <Route path='/admin/settings' component={Settings} />
                    <Route path='/admin/messages' component={Messages} />
                    <Route path='/admin/users' component={Users} />
                    <Route path='/admin/groups' component={Groups} />
                    <Route path='/admin/devices' component={Devices} />
                    <Redirect exact from='/admin' to='/admin/dashboard' />
                </Switch>
            </AdminLayout>
        );
    }
}

export default connect(
    (state) => {
        return {
            loggedIn: state.loggedIn,
            breadcrumb: state.globalBreadcrumb,
        };
    },
    {
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        pushBreadcrumbLink: (payload) => ({
            type: 'PUSH_BREADCRUMB_LINK',
            payload,
        }),
    }
)(Admin);
