import './TableTree.scss';

import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import {
    Accordion,
    Button,
    Card,
    Col,
    Modal,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
    useAccordionToggle,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import apiCall from '../../helpers/apiCall';
import GroupForm from '../../pages/Admin/Groups/GroupForm';

class TableTree extends Component {
    state = {
        items: [],
        loading: true,
        accordionOpen: false,
        showNewGroupFormModal: false,
    };

    async componentDidMount() {
        await this.loadTableData();
    }

    loadTableData = async () => {
        if (!this.state.loading) {
            this.setState({
                loading: true,
            });
        }

        const id = this.props.loadId ?? 'roots';

        let query = this.props.location.search
            ? new URLSearchParams(this.props.location.search)
            : new URLSearchParams();

        query.set('id', id);

        const { response, message, success } = await apiCall(
            this.props.apiCall.method,
            this.props.apiCall.path + '?' + query,
            this.props.apiCall.payload !== undefined
                ? this.props.apiCall.payload
                : null,
            this.props.apiCall.auth !== undefined
                ? this.props.apiCall.auth
                : true
        );

        if (success) {
            this.setState({
                items: response.docs,
                loading: false,
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    CustomAccordionToggle = ({ children, eventKey }) => {
        const toggleAccordion = useAccordionToggle(eventKey, async () => {
            this.setState({
                accordionOpen: !this.state.accordionOpen,
            });
        });

        return <Card.Header onClick={toggleAccordion}>{children}</Card.Header>;
    };

    toggleShowNewGroupModal = () => {
        this.setState({
            showNewGroupFormModal: !this.state.showNewGroupFormModal,
        });
    };

    render() {
        const {
            state: { items },
            props: { itemForm: ItemForm },
            CustomAccordionToggle,
        } = this;

        return (
            <div className='table-tree'>
                <header className='mb-4'>
                    <Row>
                        <Col>
                            {!this.props.loadId ? (
                                <h3 className='flex items-center m-0 h-full'>
                                    {this.props.pluralWord}
                                </h3>
                            ) : (
                                <h5 className='flex items-center m-0 h-full'>
                                    Sub{this.props.pluralWord}
                                </h5>
                            )}
                        </Col>
                        <Col>
                            <Button
                                variant='primary'
                                className='float-right'
                                onClick={this.toggleShowNewGroupModal}>
                                Create new{' '}
                                {(this.props.loadId ? 'sub' : '') +
                                    this.props.singularWord}
                            </Button>
                        </Col>
                    </Row>
                </header>
                {this.state.loading ? (
                    <div className='center-loading big'>
                        <Spinner animation='border' />
                    </div>
                ) : (
                    <Accordion>
                        {!items.length ? (
                            <div>
                                There isn&apos;t anything in here yet, try
                                creating some {this.props.pluralWord}
                            </div>
                        ) : (
                            <div className='table-tree__items'>
                                {items.map((item) => (
                                    <Card key={item._id}>
                                        <CustomAccordionToggle
                                            eventKey={item._id}>
                                            <Row>
                                                <Col className='flex items-center'>
                                                    {item.name}
                                                </Col>
                                                <Col className='flex justify-end table-tree__controls'>
                                                    {this.props.rowButtons.map(
                                                        (button) => {
                                                            if (
                                                                button.condition &&
                                                                typeof button.condition ===
                                                                    'function' &&
                                                                !button.condition(
                                                                    item
                                                                )
                                                            ) {
                                                                return null;
                                                            }

                                                            let {
                                                                    url,
                                                                    icon,
                                                                    clickCallback,
                                                                    variant,
                                                                } = button,
                                                                el;

                                                            if (
                                                                typeof clickCallback ===
                                                                'function'
                                                            ) {
                                                                el = (
                                                                    <Button
                                                                        variant={
                                                                            variant ??
                                                                            'primary'
                                                                        }
                                                                        size='sm'
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            clickCallback(
                                                                                e,
                                                                                item,
                                                                                this
                                                                                    .loadTableData
                                                                            );
                                                                        }}>
                                                                        <Fa
                                                                            icon={
                                                                                icon
                                                                            }
                                                                        />
                                                                    </Button>
                                                                );
                                                            } else {
                                                                if (url) {
                                                                    url = url.replace(
                                                                        /\/:([a-zA-Z_]*)/,
                                                                        (
                                                                            match,
                                                                            p1
                                                                        ) =>
                                                                            `/${item[p1]}`
                                                                    );
                                                                } else {
                                                                    throw new Error(
                                                                        'You need to provide either a clickCallback or an url for an element of rowButtons property'
                                                                    );
                                                                }

                                                                el = (
                                                                    <Button
                                                                        as={
                                                                            Link
                                                                        }
                                                                        variant={
                                                                            variant ??
                                                                            'primary'
                                                                        }
                                                                        size='sm'
                                                                        to={
                                                                            url
                                                                        }>
                                                                        <Fa
                                                                            icon={
                                                                                icon
                                                                            }
                                                                        />
                                                                    </Button>
                                                                );
                                                            }

                                                            return (
                                                                <OverlayTrigger
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip
                                                                            id={`tooltip-${
                                                                                item._id
                                                                            }-${button.text
                                                                                .toLocaleLowerCase()
                                                                                .replace(
                                                                                    /\\s+/g,
                                                                                    ''
                                                                                )}`}>
                                                                            {
                                                                                button.text
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                    key={`${
                                                                        item._id
                                                                    }-${button.text
                                                                        .toLocaleLowerCase()
                                                                        .replace(
                                                                            /\\s+/g,
                                                                            ''
                                                                        )}`}>
                                                                    {el}
                                                                </OverlayTrigger>
                                                            );
                                                        }
                                                    )}
                                                </Col>
                                            </Row>
                                        </CustomAccordionToggle>
                                        <Accordion.Collapse eventKey={item._id}>
                                            <div className='padding'>
                                                <ItemForm data={item} />
                                                <br />
                                                {this.state.accordionOpen && (
                                                    <TableTree
                                                        items={
                                                            this.state.children
                                                        }
                                                        pluralWord={
                                                            this.props
                                                                .pluralWord
                                                        }
                                                        singularWord={
                                                            this.props
                                                                .singularWord
                                                        }
                                                        itemForm={ItemForm}
                                                        loadId={item._id}
                                                        apiCall={
                                                            this.props.apiCall
                                                        }
                                                        location={
                                                            this.props.location
                                                        }
                                                        rowButtons={
                                                            this.props
                                                                .rowButtons
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </div>
                        )}
                    </Accordion>
                )}
                <Modal
                    show={this.state.showNewGroupFormModal}
                    onHide={this.toggleShowNewGroupModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create new group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <GroupForm
                            onSuccess={async () => {
                                this.toggleShowNewGroupModal();
                                await this.loadTableData();
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(TableTree));
