import './App.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import MainLayout from './layouts/Main/Main';
import { ChangePassword, Login, Profile, ResetPassword } from './pages/Account';
import { Admin } from './pages/Admin';
import { Logs } from './pages/Home';
import { Notifications } from './pages/Notifications';
import { Policy } from './pages/Policy';

export default class App extends Component {
    render() {
        return (
            <Router>
                <div id='app'>
                    <MainLayout>
                        <Switch>
                            <Route path='/logs/:deviceId?' component={Logs} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/profile' component={Profile} />
                            <Route
                                exact
                                path='/reset-password'
                                component={ResetPassword}
                            />
                            <Route
                                exact
                                path='/change-password'
                                component={ChangePassword}
                            />
                            <Route path='/admin' component={Admin} />
                            <Route
                                exact
                                path='/notifications'
                                component={Notifications}
                            />
                            <Route path='/terms_of_service'>
                                <Policy
                                    keyword='terms_of_service'
                                    title='Terms Of Service'
                                />
                            </Route>
                            <Route path='/privacy_policy'>
                                <Policy
                                    keyword='privacy_policy'
                                    title='Privacy Policy'
                                />
                            </Route>
                            <Redirect exact from='/' to='/logs' />
                        </Switch>
                    </MainLayout>
                </div>
            </Router>
        );
    }
}
