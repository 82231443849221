import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Device from './Device';
import DevicesList from './DevicesList';

class Devices extends Component {
    constructor(props) {
        super(props);

        props.pushBreadcrumbLink({
            text: 'Devices',
            path: '/admin/devices',
        });
    }

    componentWillUnmount() {
        this.props.removeBreadcrumbLink({
            text: 'Devices',
            path: '/admin/devices',
        });
    }

    render() {
        return (
            <div id='users'>
                <Switch>
                    <Route
                        exact
                        path='/admin/devices'
                        component={DevicesList}
                    />
                    <Route path='/admin/devices/:id' component={Device} />
                </Switch>
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(Devices);
