/**
 Once this file runs our store is created. The store format looks something like this:
 {
    loggedIn: someValue,
    adminTab: someValue,
    studentTab: someValue
}
 */

import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers/rootReducer';

export default configureStore({
    // replaces createStore but makes configuration easier.
    reducer: rootReducer,
    //middleware: [thunk],
});
