import './ApiTable.scss';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
// some external components we need to render this
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// import this, it's a helper function to make api call to our backend
import apiCall from '../../helpers/apiCall';
import Pagination from '../Pagination';

class ApiTable extends Component {
    constructor(props) {
        super(props); // whenever you use the constructor, have this line here

        // we create this to later reuse it throughout other methods within the class
        // this makes use of the URLSearchParams class available javascript
        this.searchParams = props.location.search
            ? new URLSearchParams(props.location.search)
            : new URLSearchParams();

        this.timeOutFunction = null;
        // state needs to be initialized in the constructor, each state variable goes into this.state
        this.state = {
            tableData: null, // here we will save the api response everytime a call is made
            search: this.searchParams.get('search')
                ? decodeURI(this.searchParams.get('search'))
                : '',
            loading: true,
        };
    }

    // life-cycle method to run some code each time the component is created( or re-created, eg. state change )
    // !!! this doesn't need to be an async function always, the async keyword is used just because
    // we need to use await inside !!!
    async componentDidMount() {
        await this.loadTableData();
    }

    // load the data again
    async componentDidUpdate(prevProps, prevState, snapshot) {
        // but load the data again just when the page changes
        if (prevProps.location.search !== this.props.location.search) {
            await this.loadTableData();
        }
    }

    // this is the code we previously had in our life-cycle method componentDidMount,
    // but we are going to reuse this code so we moved it into a separate function
    loadTableData = async () => {
        // we need to update this each time we load the data with props passed down by react-router
        // make the call to the backend to fetch the data for the table
        if (!this.state.loading) {
            this.setState({
                loading: true,
            });
        }

        const { response, message, success } = await apiCall(
            this.props.apiCall.method,
            // we concatenate the API path with the required query parameters for pagination
            this.props.apiCall.path + this.props.location.search,
            this.props.apiCall.payload !== undefined
                ? this.props.apiCall.payload
                : null,
            this.props.apiCall.auth !== undefined
                ? this.props.apiCall.auth
                : true
        );

        // if the call was successful save the response in the state
        if (success) {
            // TODO: remove this in production.
            if (typeof response.docs === 'undefined') {
                alert(
                    'The backend needs a fix, take your sweet 2 minutes to curse Adrian then tell him to fix his shitty backend!'
                );
            }
            // this is a function available in any react component to update the state
            // you can pass one or many parameters of the state at a time, you don't need to pass them all
            // every time you need to update the state, react will check that.
            this.setState({
                tableData: response,
                loading: false,
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    handleSearchChange = async (e) => {
        const input = e.target;

        this.setState(
            {
                search: input.value,
            },
            () => {
                if (this.timeOutFunction) {
                    clearTimeout(this.timeOutFunction);
                }

                this.timeOutFunction = setTimeout(() => {
                    if (input.value) {
                        this.searchParams.set(
                            'search',
                            encodeURI(this.state.search)
                        );
                    } else {
                        this.searchParams.delete('search');
                    }

                    this.searchParams.delete('page');

                    this.props.history.push(
                        this.props.location.pathname +
                            '?' +
                            this.searchParams.toString()
                    );
                }, 1000);
            }
        );
    };

    render() {
        if (this.state.tableData) {
            const { tableData } = this.state;
            return (
                <div className='table-wrapper'>
                    <div className='table-controls'>
                        <Pagination
                            className='table-pagination'
                            activePage={tableData.page}
                            totalPages={tableData.totalPages}
                        />
                        <div className='table-search'>
                            <form
                                action='/'
                                className='form'
                                onSubmit={this.handleSearchSubmit}>
                                <div className='form__field form__field--inside-icon'>
                                    <input
                                        type='text'
                                        name='search'
                                        defaultValue={this.state.search}
                                        placeholder='Search...'
                                        onChange={this.handleSearchChange}
                                    />
                                    <Button
                                        variant='primary'
                                        type='submit'
                                        size='sm'>
                                        <Fa icon={faSearch} />
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {tableData.docs.length ? (
                        <div className='table'>
                            <header>
                                {this.props.columns.map((column) => {
                                    return (
                                        <div
                                            key={column.field}
                                            style={{
                                                minWidth:
                                                    column.minWidth || 'auto',
                                                maxWidth:
                                                    column.maxWidth || 'auto',
                                            }}
                                            className={`col${
                                                column.headClassName
                                                    ? ' ' + column.headClassName
                                                    : ''
                                            }`}>
                                            {column.text}
                                        </div>
                                    );
                                })}
                                {this.props.rowButtons &&
                                    this.props.rowButtons.length && (
                                        <div
                                            className='col col--controls'
                                            style={{
                                                minWidth:
                                                    this.props.rowButtons
                                                        .length *
                                                        2.06 +
                                                    (this.props.rowButtons
                                                        .length -
                                                        1) *
                                                        0.75 +
                                                    1.3 +
                                                    'rem',
                                            }}
                                        />
                                    )}
                            </header>
                            <main>
                                <div
                                    className={`table-loading ${
                                        !this.state.loading ? ' hide' : ''
                                    }`}>
                                    <Spinner animation='border' />
                                </div>
                                {tableData.docs.map((doc) => (
                                    <div key={doc._id} className='row'>
                                        {this.props.columns.map((column, i) => (
                                            <div
                                                key={`${doc._id}-${i}`}
                                                style={{
                                                    minWidth:
                                                        column.minWidth ||
                                                        'auto',
                                                    maxWidth:
                                                        column.maxWidth ||
                                                        'auto',
                                                }}
                                                className={`col${
                                                    column.className
                                                        ? ' ' + column.className
                                                        : ''
                                                }`}>
                                                {typeof column.field ===
                                                'function'
                                                    ? column.field(doc)
                                                    : doc[column.field]}
                                            </div>
                                        ))}
                                        {this.props.rowButtons &&
                                            this.props.rowButtons.length && (
                                                <div
                                                    className='col col--controls'
                                                    style={{
                                                        minWidth:
                                                            this.props
                                                                .rowButtons
                                                                .length *
                                                                2.06 +
                                                            (this.props
                                                                .rowButtons
                                                                .length -
                                                                1) *
                                                                0.75 +
                                                            1.3 +
                                                            'rem',
                                                    }}>
                                                    {this.props.rowButtons.map(
                                                        (button) => {
                                                            if (
                                                                button.condition &&
                                                                typeof button.condition ===
                                                                    'function' &&
                                                                !button.condition(
                                                                    doc
                                                                )
                                                            ) {
                                                                return null;
                                                            }

                                                            let {
                                                                    url,
                                                                    icon,
                                                                    variant,
                                                                    clickCallback,
                                                                } = button,
                                                                el;

                                                            if (
                                                                typeof clickCallback ===
                                                                'function'
                                                            ) {
                                                                el = (
                                                                    <Button
                                                                        variant={
                                                                            variant ??
                                                                            'primary'
                                                                        }
                                                                        size='sm'
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            clickCallback(
                                                                                e,
                                                                                doc,
                                                                                this
                                                                                    .loadTableData
                                                                            );
                                                                        }}>
                                                                        <Fa
                                                                            icon={
                                                                                icon
                                                                            }
                                                                        />
                                                                    </Button>
                                                                );
                                                            } else {
                                                                if (url) {
                                                                    url = url.replace(
                                                                        /\/:([a-zA-Z_]*)/,
                                                                        (
                                                                            match,
                                                                            p1
                                                                        ) =>
                                                                            `/${doc[p1]}`
                                                                    );
                                                                } else {
                                                                    throw new Error(
                                                                        'You need to provide either a clickCallback or an url for an element of rowButtons property'
                                                                    );
                                                                }

                                                                el = (
                                                                    <Button
                                                                        as={
                                                                            Link
                                                                        }
                                                                        variant={
                                                                            variant ??
                                                                            'primary'
                                                                        }
                                                                        size='sm'
                                                                        to={
                                                                            url
                                                                        }>
                                                                        <Fa
                                                                            icon={
                                                                                icon
                                                                            }
                                                                        />
                                                                    </Button>
                                                                );
                                                            }

                                                            return (
                                                                <OverlayTrigger
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip
                                                                            id={`tooltip-${
                                                                                doc._id
                                                                            }-${button.text
                                                                                .toLocaleLowerCase()
                                                                                .replace(
                                                                                    /\\s+/g,
                                                                                    ''
                                                                                )}`}>
                                                                            {
                                                                                button.text
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                    key={`${
                                                                        doc._id
                                                                    }-${button.text
                                                                        .toLocaleLowerCase()
                                                                        .replace(
                                                                            /\\s+/g,
                                                                            ''
                                                                        )}`}>
                                                                    {el}
                                                                </OverlayTrigger>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                ))}
                            </main>
                        </div>
                    ) : (
                        <div className='table color--light padding--double text--center font--medium'>
                            No results were found for your search
                        </div>
                    )}
                    <div className='table-controls table-controls--bottom'>
                        <Pagination
                            className='table-pagination'
                            activePage={tableData.page}
                            totalPages={tableData.totalPages}
                        />
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

// we export de class component passed through withRouter, which adds data needed to the our ApiTable component
export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(ApiTable));
