import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Group from './Group';
import GroupsList from './GroupsList';

class Groups extends Component {
    constructor(props) {
        super(props);

        props.pushBreadcrumbLink({
            text: 'Groups',
            path: '/admin/groups',
        });
    }

    componentWillUnmount() {
        this.props.removeBreadcrumbLink({
            text: 'Groups',
            path: '/admin/groups',
        });
    }

    render() {
        return (
            <div id='groups'>
                <Switch>
                    <Route exact path='/admin/groups' component={GroupsList} />
                    <Route path='/admin/groups/:id' component={Group} />
                </Switch>
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(Groups);
