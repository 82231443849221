import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class MenuItem extends Component {
    render() {
        const { props } = this;

        return (
            <Link
                to={props.to}
                className={`side-menu__item${
                    this.props.breadcrumbList[props.to]
                        ? ' side-menu__item--active'
                        : ''
                }`}
                key={props.text}>
                <Fa icon={props.icon} />
                <span>{props.text ? props.text : props.children}</span>
            </Link>
        );
    }
}

export default connect((state) => {
    return {
        breadcrumbList: state.globalBreadcrumb,
    };
})(MenuItem);
