import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SelectSearch from 'react-select-search';

import apiCall from '../../../helpers/apiCall';

class GroupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            initialData: props.data
                ? {
                      name: props.data.name,
                      parent: props.data.parent,
                      description: props.data.description,
                  }
                : undefined,
            name: props.data ? props.data.name : '',
            parent: props.data ? props.data.parent : undefined,
            description: props.data ? props.data.description : '',
            noParent: props.data ? !props.data.parent : true,
            parentOptions: [],
            changesExist: false,
        };
    }

    async componentDidMount() {
        const { response, success } = await apiCall(
            'GET',
            '/groups?perPage=11'
        );
        let parentOptions = response.docs;

        if (this.props.data) {
            parentOptions = parentOptions.filter(
                (group) => group._id !== this.props.data._id
            );
        }

        parentOptions = parentOptions.map(({ _id: value, name }) => ({
            value,
            name,
        }));

        if (parentOptions.length > 10) {
            parentOptions.pop();
        }

        if (success) {
            this.setState({
                parentOptions,
            });
        }
    }

    handleInputChange = (e) => {
        const input = e.target,
            newState = {
                [input.name]: input.value,
            };

        if (
            this.props.data &&
            this.state.initialData[input.name] !== input.value &&
            !this.state.changesExist
        ) {
            newState.changesExist = true;
        }

        this.setState(newState);
    };

    discardChanges = (e) => {
        e.preventDefault();

        this.setState({
            name: this.state.initialData.name,
            description: this.state.initialData.description,
            parent: this.state.initialData.parent,
            noParent: !this.state.initialData.parent,
            changesExist: false,
        });
    };

    toUpdate = () => {
        let toReturn = {};

        for (const key of Object.keys(this.state.initialData)) {
            if (this.state[key] !== this.state.initialData[key]) {
                toReturn[key] = this.state[key];
            }
        }

        return toReturn;
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        // if we are editing an existing group we should check what to patch, else just send everything for the post request
        let payload = this.props.data
            ? this.toUpdate()
            : {
                  name: this.state.name,
                  description: this.state.description,
              };
        // if it's a new group and we have a parent set, send that too
        if (!this.props.data && !this.state.noParent) {
            payload.parent = this.state.parent;
        }

        const { success, message } = await apiCall(
            this.props.data ? 'PATCH' : 'POST',
            '/groups/' + (this.props.data ? this.props.data._id : ''),
            payload
        );

        if (this.state.noParent && payload.parent) {
            payload.parent = null;
        }

        this.props.setGlobalAlert({
            type: !success ? 'error' : 'success',
            message,
        });

        if (success) {
            this.setState({
                initialData: {
                    ...this.state.initialData,
                    ...payload,
                },
                changesExist: false,
            });

            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        }
    };

    render() {
        const { message } = this.state;
        return (
            <div className={'form' + (message ? ' form--with-error' : '')}>
                {message && (
                    <Alert variant={message.type}>{message.text}</Alert>
                )}
                <div className='form__content'>
                    <form action='/' onSubmit={this.handleFormSubmit}>
                        <div className='form__field'>
                            <label htmlFor='name'>Name</label>
                            <input
                                type='text'
                                name='name'
                                onChange={this.handleInputChange}
                                value={this.state.name}
                            />
                        </div>
                        <div className='form__field'>
                            <label htmlFor='description'>Description</label>
                            <textarea
                                name='description'
                                onChange={this.handleInputChange}
                                value={this.state.description}
                            />
                        </div>
                        <div
                            className={`form__field${
                                this.state.noParent ? ' disabled' : ''
                            }`}>
                            <label htmlFor='parent'>Parent group</label>
                            <SelectSearch
                                options={this.state.parentOptions}
                                getOptions={(query) => {
                                    return new Promise((resolve, reject) => {
                                        apiCall(
                                            'GET',
                                            '/groups?id=all&search=' + query
                                        )
                                            .then(
                                                ({ response }) => response.docs
                                            )
                                            .then((groups) => {
                                                resolve(
                                                    groups.map(
                                                        ({
                                                            _id: value,
                                                            name,
                                                        }) => ({ value, name })
                                                    )
                                                );
                                            })
                                            .catch(reject);
                                    });
                                }}
                                value={this.state.parent}
                                placeholder='No parent'
                                onChange={(parent) => {
                                    this.setState({
                                        parent,
                                    });
                                }}
                                search
                                emptyMessage="Couldn't find the group you are searching for"
                            />
                        </div>
                        <div className='mt-1'>
                            <input
                                type='checkbox'
                                name='noParent'
                                checked={this.state.noParent}
                                onChange={() => {
                                    this.setState({
                                        noParent: !this.state.noParent,
                                    });
                                }}
                            />
                            &nbsp;
                            <label htmlFor='noParent'>No parent</label>
                        </div>
                        <div className='form__buttons'>
                            <Button
                                variant='primary'
                                onClick={this.handleFormSubmit}>
                                Save
                            </Button>
                            {this.props.data && (
                                <Button
                                    variant='outline-danger'
                                    disabled={!this.state.changesExist}
                                    onClick={this.discardChanges}>
                                    Discard changes
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(GroupForm));
