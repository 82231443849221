// import rootReducer from './rootReducer';

// export default rootReducer;

// This file combines all the reducers we create. This keeps the reducers structure organised.

import { combineReducers } from 'redux';

import { loggedIn } from './accountReducer';
import { globalAlert, globalBreadcrumb } from './globalReducer';
import { allLessons, currentLesson } from './lessonReducers';

export default combineReducers({
    loggedIn,
    globalAlert,
    globalBreadcrumb,
    currentLesson,
    allLessons,
});
